import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { TokenStorageService } from '../services/token-storage.service';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private token: TokenStorageService, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    const token = this.token.getToken();
    const user = this.token.getUser();
    if (token && user) {
      authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token + ' ' + user.username) });
    } else if (token) {
      authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
    }

    const handleHttpError = (error: HttpErrorResponse) => {
      if (error.status === 401) {
        // window.sessionStorage.clear();
        // localStorage.clear();
        // Swal.fire('Error', 'No tienes autorización para esta acción, comunicase con el administrador.', 'error');
        // this.router.navigate(['/login']);
        // setTimeout(() => {
          // window.location.reload();
        // }, 2000);
      } else if (error.status === 403) {
        Swal.fire('Error', 'Petición denegada, comunicase con el administrador.', 'error');
      } else if (error.status === 429) {
        Swal.fire('Error', 'Hay demasiadas solicitudes. Por favor inténtalo más tarde.', 'error');
      } else if (error.status === 500) {
        if (error.error == undefined) {
          Swal.fire('Error', 'Ups... algo ha ocurrido, por favor intente de nuevo. ' + error.error, 'error');
        } else if (error.error.message != undefined) {
          Swal.fire('Error', error.error.message, 'error');
        }
      } else if (error.error instanceof ErrorEvent) {
        Swal.fire('Error', 'Ups... algo ha ocurrido, por favor intente de nuevo. ' + error.message, 'error');
      }

      return throwError(error);
    };

    return next.handle(authReq).pipe(
      catchError(handleHttpError)
    );
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];
